<template>
	<div>
		<TripHeader />
		<div class="outer-wrapper">

			<div v-if="loading || trip.loading" style="margin-top: 20px;">
				<Loader :loading="loading || trip.loading"/>
			</div>

			<div class="inner-wrapper" v-if="!trip.loading && !loading">

				<div class="delete-warning">
					If you delete this trip, all of your planned trip items will be deleted.
				</div>

				<div v-if="Object.keys(trip.tripItems).length > 0">
					<div class="notifications">
						<div v-for="tripItem in trip.tripItems" :key="tripItem.id">
							<div class="notifications-inner green" v-if="tripItem.bookingStatus.code == 'booked'">
								{{ tripItem.name }} / <b>Booked</b>
							</div>
							<div class="notifications-inner" v-if="tripItem.alerts.all.length > 0" :class="tripItem.alerts.all[0].alert_code">
								{{ tripItem.name }} / <b>{{ tripItem.alerts.all[0].main_message }}</b>
							</div>
						</div>
					</div>
				</div>

				<div class="delete-confirmation">
					<div class="msg">Do you want to delete?</div>
					<button class="button small blue" @click="deleteTrip()">Yes</button>
					<button class="button small" @click="backToTrip()">No</button>
				</div>
				
			</div>
		</div>
	</div>
</template>
<style scoped>
	.outer-wrapper {
		position: relative;
		/* min-height: 660px; */
		/* padding-bottom: 60px; */
	}
	.inner-wrapper {
		text-align: center;
	}
	
	.delete-warning {
		font-size: 14px;
		font-weight: 500;
		color: black;
		margin: 38px auto;
		text-align: center;
	}
	.notifications {
		margin-bottom: 38px;
		width: 320px;
		display: inline-block;
	}
	.delete-confirmation {
		font-weight: 600;
		font-size: 20px;
		color: #1D1D1D;
		text-align: center;
	}
	.delete-confirmation .msg {
		margin-bottom: 44px;
	}
	.delete-confirmation .button {
		margin: 5px 5px;
		width: 157px;
	}
</style>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Menu from '@/components/Menu.vue'
import TopLeftIcon from '@/components/TopLeftIcon.vue'
import { tripService } from '@/services/tripService.js';
import router from '@/router';
import helpers from '@/helpers/helpers.js';
import Loader from '@/components/Loader.vue';
import TripHeader from "@/components/trips/TripHeader.vue";

export default {
	data() {
		return {
			trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : null,

			loading: true,
		}
	},
	computed: {
		...mapState({
			trip: state => state.trip,
		}),
	},
	methods: {
		formatDate: (date) => helpers.formatDate(date),
		...mapActions({
			alertSuccess: "alert/success",
			alertError: "alert/error",
		}),
		deleteTrip() {
			// Popup confirm first? What are the designs?
			this.loading = true;
			tripService.deleteTrip(this.trip_id)
				.then(response => {
					this.alertSuccess('Trip deleted successfully');
					router.push({
						name: "My Trips",
					})
					this.loading = false;
				})
				.catch(error => {
					this.$rollbar.error(error);
					this.alertError(error);
					this.loading = false;
				});
		},
		backToTrip() {
			router.push({
				name: 'Trip',
				params: {
					trip_id: this.trip_id,
				}
			})
		}
	},
	created() {
		if(!this.trip.tripID) { // Refresh probably lost loaded state (can no longer show header)
			this.backToTrip();
		}
		this.loading = false;
	},
	components: {
		Menu,
		TopLeftIcon,
		Loader,
		TripHeader,
	}
};
</script>